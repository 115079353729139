<template>
  <div class="content">
    <div class="peaceful">
      <div class="peaceful-title">平安城市</div>
      <div class="peaceful-box">
        <div
          class="peaceful-box-item"
          v-for="(item, index) in peacefulImg"
          :key="index"
        >
          <img :src="item.url" alt="" />
          <div>{{ item.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
 
<script>
export default {
  components: {},
  name: "",
  data() {
    return {
      peacefulImg: [
        {
          url: require("../../../../assets/image/cityImage/bom1.png"),
          name: "治安监控前端",
        },
        {
          url: require("../../../../assets/image/cityImage/bom2.png"),
          name: "乡村监控前端",
        },
        {
          url: require("../../../../assets/image/cityImage/bom3.png"),
          name: "实景作战系统",
        },
        {
          url: require("../../../../assets/image/cityImage/bom4.png"),
          name: "微卡口系统",
        },
        {
          url: require("../../../../assets/image/cityImage/bom5.png"),
          name: "人脸识别系统",
        },
        {
          url: require("../../../../assets/image/cityImage/bom6.png"),
          name: "综合视频监控共享平台",
        },
        {
          url: require("../../../../assets/image/cityImage/bom7.png"),
          name: "公安治安综合管控平台",
        },
        {
          url: require("../../../../assets/image/cityImage/bom8.png"),
          name: "公安指挥调度系统",
        },
      ],
    };
  },

  methods: {},
};
</script>
 
<style scoped lang="scss">
.peaceful {
  width: 80%;
  height: 800px;
  margin: 0 10%;
  margin-top: 19px;
  padding: 0 5%;
  box-sizing: border-box;
  overflow: hidden;
  background-color: #fff;
  margin-bottom: 50px;
  .peaceful-title {
    font-size: 40px;
    padding: 4% 0;
    font-weight: 600;
    color: #333333;
  }
  .peaceful-box {
    display: flex;
    flex-wrap: wrap;
    .peaceful-box-item {
      width: 24%;
      margin: 0 1% 2% 0;
      text-align: center;
      font-size: 24px;
      font-weight: 600;
      color: #333333;
      img {
        width: 100%;
      }
    }
  }
}
</style>